import { merchantController, usersController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { authUtils, notify } from '@/shared/utils';

export const addItems = async ({
  sourceEntity,
  items,
  key,
  removeFn,
  addFn,
}) => {
  if (!items.length) return;
  const removedItems = sourceEntity?.[key]?.filter(
    (el) => !items.map((item) => item.id).includes(el.id)
  );
  const newItems = items.filter(
    (item) => !(sourceEntity?.[key]?.map((el) => el.id) || []).includes(item.id)
  );

  if (removedItems?.length) {
    await Promise.all(
      removedItems.map((el) =>
        removeFn({
          id: sourceEntity.id,
          [key.slice(0, -1)]: {
            id: el.id,
          },
        })
      )
    );
  }

  if (newItems.length) {
    await Promise.all(
      newItems.map((el) =>
        addFn({
          id: sourceEntity.id,
          [key.slice(0, -1)]: {
            id: el.id,
          },
        })
      )
    );
  }
};

export default (userFilterCallback) => ({
  data: () => ({
    usersDictionary: [],
    merchantsDictionary: [],
  }),

  async created() {
    await Promise.all([this.loadUsers(), this.loadMerchants()]);
  },

  methods: {
    addItems,
    async loadUsers() {
      try {
        const { data } = await usersController(
          authUtils.getRoleByHierarchy()
        ).getUsers();
        this.usersDictionary = data.filter(
          userFilterCallback ||
            ((el) =>
              el.roles.includes(ROLES.ROLE_AGENT) ||
              el.roles.includes(ROLES.ROLE_OPERATOR))
        );
      } catch (e) {
        console.error(e);
        notify.error(e.message);
      }
    },

    async loadMerchants() {
      try {
        const { data } = await merchantController.getMerchants();
        this.merchantsDictionary = data;
      } catch (e) {
        console.error(e);
        notify.error(e.message);
      }
    },
  },
});
