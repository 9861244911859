<template>
  <d-edit-card
    :key="$route.params.id"
    :id="$route.params.id"
    :fetch-function="payoutMethodController.getPayoutMethodById"
    :entity="form"
    prev-page="PayoutMethodsList"
    :card-title="`Payout Method ${isEditingForm ? 'updating' : 'creation'}`"
    remove-confirm-title="Are you sure you want to delete this payout method?"
    remove-success-title="Payout method successfully removed"
    :remove-function="payoutMethodController.removePayoutMethod"
    :submit-function="submitForm"
    redirect-after-creation="PayoutMethodsEdit"
  >
    <template #default="{ data }">
      <q-card-section class="col">
        <div class="row">
          <div
            class="col-xs-12 col-md-6 q-col-gutter-md"
            :class="$q.screen.lt.md ? 'q-mb-sm' : 'q-pr-md'"
          >
            <q-input
              v-model.trim="data.name"
              outlined
              label="Name"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-input
              v-model.trim="data.code"
              outlined
              label="Code"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-select
              v-model="data.currency"
              :options="currencyDictionary"
              :rules="[rules.notNullField]"
              outlined
              clearable
              label="Currency"
              hint="Required field*"
              option-value="id"
              option-label="name"
            />
            <d-user-select
              v-model="data.users"
              use-input
              :clear-value="[]"
              :options="usersDictionary"
              :rules="[rules.notNullField]"
            />
            <q-select
              outlined
              v-model="data.merchants"
              :options="merchantsDictionary"
              option-value="id"
              option-label="name"
              label="Merchants"
              multiple
              hint="Required field*"
              clearable
              :rules="[rules.notNullField]"
            />
          </div>
          <div class="col-xs-12 col-md-6">
            <d-fees-table :items.sync="data.userFee" :agents="data.users" />
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ loading, remove }">
      <d-edit-form-actions
        :is-editing-form="isEditingForm"
        prev-page="PayoutMethodsList"
        @remove="remove"
      />
    </template>
  </d-edit-card>
</template>

<script>
import { DEditFormActions } from '@/features/edit-form-actions';
import { DFeesTable } from '@/features/fees-table';
import DUserSelect from '@/features/user-select';
import DEditCard from '@/layouts/edit-card';
import {
  payoutMethodController as api,
  currencyController,
} from '@/shared/api';
import { ROLES } from '@/shared/constants';
import helperMixin from '@/shared/mixins/refillAndPayoutMethods';
import withIsEditingForm from '@/shared/mixins/withIsEditingForm';
import { authUtils } from '@/shared/utils';

export default {
  components: { DEditCard, DUserSelect, DEditFormActions, DFeesTable },
  mixins: [
    helperMixin(
      (el) =>
        el.roles.includes(ROLES.ROLE_AGENT) ||
        el.roles.includes(ROLES.ROLE_SUPERVISOR)
    ),
    withIsEditingForm(),
  ],
  data: () => ({
    form: {
      name: null,
      code: null,
      currency: null,
      users: [],
      merchants: [],
      userFee: [],
    },
    payoutMethodController: api(authUtils.getRoleByHierarchy()),
    rules: {
      notNullField: (val) => {
        return val?.toString().length > 0 || 'Please type value';
      },
    },
    currencyDictionary: [],
  }),

  methods: {
    async submitForm({ name, code, id, users, merchants, userFee, currency }) {
      const commonEntityRequest = async () => {
        const action = this.isEditingForm
          ? this.payoutMethodController.updatePayoutMethod
          : this.payoutMethodController.createPayoutMethod;

        const { data } = await action({
          id,
          name,
          code,
          currencyId: currency?.id,
          userFee: this.isEditingForm
            ? userFee
            : // eslint-disable-next-line no-unused-vars
              userFee.map(({ id, ...props }) => props),
        });

        return data;
      };

      const payout = await commonEntityRequest();
      await Promise.all([
        this.addItems({
          sourceEntity: payout,
          items: users,
          key: 'users',
          removeFn: this.payoutMethodController.removeUserAtPayoutMethodType,
          addFn: this.payoutMethodController.addUserToPayoutMethodType,
        }),
        this.addItems({
          sourceEntity: payout,
          items: merchants,
          key: 'merchants',
          removeFn:
            this.payoutMethodController.removeMerchantToPayoutMethodType,
          addFn: this.payoutMethodController.addMerchantToPayoutMethodType,
        }),
      ]);
      return {
        data: { id: payout.id },
      };
    },
  },
  async mounted() {
    const currencyResponse = await currencyController(
      authUtils.getRoleByHierarchy()
    ).getCurrency();

    this.currencyDictionary = Object.freeze(currencyResponse.data);
  },
};
</script>
