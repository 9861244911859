<template>
  <q-table
    :columns="columns"
    :data="localItems"
    hide-pagination
    separator="cell"
    row-key="id"
  >
    <template #top>
      <q-btn
        color="primary"
        :disable="!isValid || !availableAgents.length"
        label="Add property"
        @click="addProperty()"
      />
    </template>
    <template #body-cell-user-id="props">
      <q-td :props="props">
        <q-select
          dense
          borderless
          debounce="300"
          label="Agent"
          :value="props.row.userId"
          option-label="username"
          option-value="id"
          map-options
          emit-value
          :rules="rules"
          :options="availableAgents"
          @input="update($event, props, 'userId')"
        >
          <template #selected>
            {{ getAgentNameById(props.row.userId) }}
          </template>
        </q-select>
      </q-td>
    </template>
    <template #body-cell-percent="props">
      <q-td :props="props">
        <q-input
          label="Percent"
          type="number"
          dense
          borderless
          debounce="300"
          placeholder="Click to enter value"
          :value="props.row.percent"
          :rules="rules"
          @input="update(+$event, props, 'percent')"
        />
      </q-td>
    </template>
    <template #body-cell-action="props">
      <q-td :props="props">
        <q-btn
          flat
          fab-mini
          icon="mdi-delete-empty-outline"
          @click="removeItem(props.row.id)"
        />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import { uid } from 'quasar';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    agents: {
      type: [Array, Object],
      required: true,
    },
  },
  data: () => ({
    columns: [
      {
        name: 'user-id',
        field: 'userId',
        label: 'Key',
        align: 'center',
      },
      {
        name: 'percent',
        field: 'percent',
        label: 'Value',
        align: 'center',
      },
      {
        name: 'action',
        field: 'action',
        label: '',
        align: 'center',
      },
    ],
    localItems: [],
    availableAgents: [],
    rules: [(val) => !!val || 'Value must be not empty.'],
  }),
  watch: {
    items: {
      handler(val) {
        this.localItems = val;
      },
    },
    agents: {
      handler(val) {
        this.availableAgents = val;

        if (!val.length) {
          this.$emit('update:items', []);
          return;
        }

        const availableIds = val.map((el) => el.id);
        this.localItems.forEach((el) => {
          if (!availableIds.includes(el.userId)) {
            this.removeItem(el.id);
          }
        });
      },
      deep: true,
    },
    localItems: {
      handler(val) {
        const ids = val.map((el) => el.userId);
        this.availableAgents = this.agents.filter((el) => !ids.includes(el.id));
      },
      deep: true,
    },
  },
  computed: {
    isValid() {
      const isIncludesNulls = this.localItems
        .flatMap((el) => Object.values(el))
        .includes(null);

      return !isIncludesNulls;
    },
  },
  methods: {
    getAgentNameById(id) {
      return this.agents.find((el) => el.id === id)?.username;
    },
    update(value, { rowIndex }, key) {
      this.localItems[rowIndex][key] = value;
      if (!Object.values(this.localItems[rowIndex]).includes(null)) {
        this.$emit('update:items', this.localItems);
      }
    },

    addProperty() {
      this.$emit('update:items', [
        { userId: null, percent: null, id: uid() },
        ...this.localItems,
      ]);
    },

    removeItem(id) {
      const value = this.items.filter((el) => el.id !== id);
      this.$emit('update:items', value);
    },
  },
};
</script>
